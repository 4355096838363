window.document.addEventListener("DOMContentLoaded", function () {
  initAjaxForms();
});

// trida pro zkryti elementu
const HIDE_CSS_CLASS = 'hidden';

// selector pro nonFieldErrors
const NON_FIELD_ERRORS_SELECTOR = '.nonFieldErrors';

// selector pro fieldErrors
const FIELD_ERRORS_SELECTOR = '.fieldErrors';

// selector elementu zobrazenho pri zdarilem odeslani formulare
const SUBMIT_SUCCESS_SELECTOR = '.submitSuccess';

// selector elementu zobrazenho pri nezdarenem odeslani formulare
const SUBMIT_ERROR_SELECTOR = '.submitError';

// selector lementu, ktre se zkryji po odeslani formulare
const HIDE_AFTER_SUBMIT_SELECTOR = '.hideAfterSubmit';

// ziskani id elementu pro zobrazeni chyby fieldu
const getFieldErrorElementId = (field) => `${field.getAttribute('name')}_errors`;

// ziskani elementu field containeru
const getFieldContainer = (field) => field.closest('.fieldContainer');

// inicializace ajax formularu
const initAjaxForms = () => {
  const forms = document.querySelectorAll("form[data-ajax-form]");
  if (!forms.length) return;
  Array.from(forms).forEach((form) => {
    createAjaxForm(form);
  });
};

// nastaveni ajax submitu formulari
const createAjaxForm = (form) => {
  form.addEventListener('submit', async (event) => {
    event.preventDefault();

    // reset zovrazenych chyb
    onBeforeSubmit(form);

    // odeslani formulare
    const formData = new FormData(form);
    const url = form.getAttribute('action');
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: { 'X-Requested-With': 'XMLHttpRequest' }, // Django CSRF
      });
      const result = await response.json();
      if (result.success) {
        onAfterSubmitSuccess(form);
      } else {
        onAfterSubmitError(form, result.errors);
      }
    } catch (error) {
      onAfterSubmitError(form);
    }
  });
};

// pred odeslanim formulare
const onBeforeSubmit = (form) => {
  // reset erroru
  const nonFieldErrorsElm = getNonFieldErrorsElement(form);
  const fieldErrorsElms = getFieldErrorsElements(form);
  [nonFieldErrorsElm, ...fieldErrorsElms].forEach((elm) => {
    setElmContent(elm, '');
    hide(elm);
  });
};

// odeslani se zdarilo
const onAfterSubmitSuccess = (form) => {
  // schovam elementy
  Array.from(form.querySelectorAll(HIDE_AFTER_SUBMIT_SELECTOR)).forEach((elm) => {
    hide(elm);
  });
  // zobrazim elementy
  Array.from(form.querySelectorAll(SUBMIT_SUCCESS_SELECTOR)).forEach((elm) => {
    show(elm);
  });
};

const onAfterSubmitError = (form, errors = null) => {
  // vraceny chyby = lze opravit a poslat znovu
  if (errors) {
    const {__all__, ...fieldErrors} = errors;
    if (__all__) showNonFieldErrors(__all__, form);
    if (fieldErrors) showFieldErrors(fieldErrors, form);
  }

  // nevraceny chyb = nejaka chyba na serveru
  else {
    Array.from(form.querySelectorAll(SUBMIT_ERROR_SELECTOR)).forEach((elm) => {
      show(elm);
    });
  }
};

const getNonFieldErrorsElement = (form) => {
  return form.querySelector(NON_FIELD_ERRORS_SELECTOR);
};

const getFieldErrorsElements = (form) => {
  const elms = form.querySelectorAll(FIELD_ERRORS_SELECTOR);
  return elms && elms?.length ? Array.from(elms) : [];
};

const setElmContent = (elm, html) => {
  if (elm?.innerHTML == undefined) return;
  elm.innerHTML = html;
};

const hide = (elm) => {
  if (!elm || !elm?.classList) return;
  elm.classList.add(HIDE_CSS_CLASS);
};

const show = (elm) => {
  if (!elm || !elm?.classList) return;
  elm.classList.remove(HIDE_CSS_CLASS);
};

const showNonFieldErrors = (nonFieldErrors, form) => {
  const errorHTML = djangoErrors2HTML(nonFieldErrors);
  if (errorHTML == '') return;
  const nonFieldErrorsElm = getNonFieldErrorsElement(form);
  setElmContent(nonFieldErrorsElm, errorHTML);
  show(nonFieldErrorsElm);
  nonFieldErrorsElm.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

const showFieldErrors = (fieldErrors, form) => {
  let firstErrorElm = null;
  for (const [key, value] of Object.entries(fieldErrors)) {
    const field = form.querySelector(`[name=${key}]`);
    if (!firstErrorElm) firstErrorElm = field;
    const errorHTML = djangoErrors2HTML(value);
    if (errorHTML == '') continue;
    const errorElmId = getFieldErrorElementId(field);
    const errorElm = document.getElementById(errorElmId);
    if (!errorElm) return;
    setElmContent(errorElm, errorHTML);
    show(errorElm);
  }
  const firstErrorFieldContainer = getFieldContainer(firstErrorElm);
  (firstErrorFieldContainer ? firstErrorFieldContainer : firstErrorElm).scrollIntoView({ behavior: 'smooth', block: 'start' });
};

const djangoErrors2HTML = (djangoError) => {
  if (!djangoError) return '';
  if (!Array.isArray(djangoError)) return `${djangoError}`;
  const messages = djangoError.map(errorItem => `<li>${errorItem.message}</li>`);
  return messages.length > 0 ? `<ul>${messages.join('')}</ul>` : '';
};
